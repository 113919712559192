import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import * as PropTypes from 'prop-types'
import { AnchorClickToNavigateWrapper, getCategoryDisplayName, getTranslateFunction, uppercaseFirstLetter } from '../utilities'
import ContactForm from '../components/contactForm/ContactForm'
import ReactVivus from 'react-vivus'
import LinkBtn from '../components/linkBtn/LinkBtn'
import { TranslatedLink } from '../components/TranslatedLink'
import CaseStudiesOtherProjects from '../components/case-studies/caseStudiesOtherProjects.js'
import SeoHelmet from '../components/seoHelmet'
import CaseStudiesPicker from '../components/case-studies/caseStudiesPicker'
import snarkdown from 'snarkdown'
import Reveal from '../components/reveal'
import ContaktUs from '../components/contactUs/ContactUs.js'

class Service extends Component {
  getProjectsByCategory = (category) => {
    const { data } = this.props
    const selector = {
      'web-corporate': () => (data.webpages_corporate_sk.childMarkdownRemark.frontmatter.other_projects),
      'web-eshop': () => (data.webpages_eshop_sk.childMarkdownRemark.frontmatter.other_projects),
      'web-portal': () => (data.webpages_portal_sk.childMarkdownRemark.frontmatter.other_projects),
      'web-microsite': () => (data.webpages_microsite_sk.childMarkdownRemark.frontmatter.other_projects),
      'web-crm': () => (data.webpages_crm_sk.childMarkdownRemark.frontmatter.other_projects),
      'app': () => (data.applications_sk.childMarkdownRemark.frontmatter.other_projects),
      'logoBranding_sk': () => (data.logoBranding_sk.childMarkdownRemark.frontmatter.other_projects),
      'graphic-logo-branding' : () => (data.logoBranding_sk.childMarkdownRemark.frontmatter.other_projects),
      'digitalPrint_sk': () => (data.digitalPrint_sk.childMarkdownRemark.frontmatter.other_projects),
      'graphic-digital/print' : () => (data.digitalPrint_sk.childMarkdownRemark.frontmatter.other_projects),
    }[category]
    if (selector) {
      return selector()
    }
  }

  constructor(props) {
    
    super(props)
    const { data } = this.props
    this.projectsData = this.getProjectsByCategory(data.service.frontmatter.category)

  }

  findCategoryName(categoryName) {
    let category = categoryName

    switch (categoryName) {
      case 'web-crm':
        category = 'web-portal'
        break;
    
      case 'app':
        category = 'app-multi-platform'
    }

    return category
    
  }

  changeCaseStudiesTitle(categoryName, lang) {
    let category = categoryName

    switch (categoryName) {
      case 'web-crm':
        category = 'web-portal'
        break;
    
      case 'app':
        category = 'app-multi-platform'
    }

    return getCategoryDisplayName(categoryName, lang)
    
  }
 
  render() {

    let { data } = this.props
    let caseStudiePicker = []
    const categoryName = data.service.frontmatter.category
    const lang = data.service.frontmatter.language
  
    if (data.caseStudies) {
      caseStudiePicker = data.caseStudies.childMarkdownRemark.frontmatter.case_studies.filter(item => item.category === this.findCategoryName(categoryName))      
    }

    const t = getTranslateFunction(lang)
    const pubUrl = (data.service.frontmatter.icon && data.service.frontmatter.icon.publicURL) ? data.service.frontmatter.icon.publicURL : null

    return (
      <Layout className="detail-reference-page service" lang={lang}>
        <AnchorClickToNavigateWrapper>

          <SeoHelmet
            title={data.service.frontmatter.seo.title}
            description={data.service.frontmatter.seo.description}
            //keywords={data.service.frontmatter.seo.keywords}
            url={this.props.location.href}
          />
          <section className="about-us-top margin-top row">
            <div className="col-xl-8 push-xl-2 pull-xl-2">
              <div className="row subpage-title align-items-center m-0">
                <div className="col-lg-6 pr-md-5 text-center pl-md-4 pl-xl-0">
                  <ReactVivus
                    id={data.service.frontmatter.title}
                    option={{
                      delay: 0,
                      start: 'inViewport',
                      file: pubUrl,
                      animTimingFunction: 'EASE',
                      type: 'delayed',
                      duration: 100,
                    }}
                    style={{ height: '355px', width: '355px' }}
                    className={'mb-5 vivus'}
                  />
                </div>
                <div className="col-lg-6 m-md-3 m-lg-0">
                  <h1>{data.service.frontmatter.title}</h1>
                  <p dangerouslySetInnerHTML={{ __html: snarkdown(data.service.frontmatter.description) }}/>
                </div>
              </div>
            </div>
          </section>
        </AnchorClickToNavigateWrapper>
        <AnchorClickToNavigateWrapper>
          <Reveal>
            <section className="two-blocks pb-md-5 mb-md-5 mt-lg-0">
              <div className="col-xl-8 push-xl-2 pull-xl-2">
                <div className="row">
                  {data.service.frontmatter.top_paragraphs && data.service.frontmatter.top_paragraphs.map((paragraph, i) => {
                    return (
                      <div className="col-md-6 item" key={i}>
                        <h2>{paragraph.title}</h2>
                        <p className="description-smaller" dangerouslySetInnerHTML={{ __html: snarkdown(paragraph.text) }}/>
                      </div>
                    )
                  })}
                </div>
              </div>
            </section>
          </Reveal>
        </AnchorClickToNavigateWrapper>

        
        {caseStudiePicker.length === 0 &&
            <Reveal>
                <section className={'row ml-0 mr-0 slider servis-slider'}>
                <div className="col-xl-9 push-xl-3">
                    <CaseStudiesOtherProjects
                    responsive = {
                        {
                            0: { items: 2.5 },
                            991: { items: 2 },
                            1555: { items: 3 },
                            1920: { items: 3 },
                            2320: { items: 3.3 },
                        }
                    }
                    category={data.service.frontmatter.category}
                    lang={'sk'} /*TODO*/
                    projects={this.projectsData}
                    title={uppercaseFirstLetter(getCategoryDisplayName(categoryName, 'sk', 'Inflected_2'))}
                    />
                </div>
                </section>
            </Reveal>
        }
            
        {caseStudiePicker.length > 0 &&
          <Reveal>
            <section className="hp-projects">
              <CaseStudiesPicker
                title={this.changeCaseStudiesTitle(categoryName, lang)}
                isRight={false}
                studies={caseStudiePicker}
                lang={data.service.frontmatter.language}
                loadLast={false}
              />
            </section>
          </Reveal>
        }

   
          <Reveal>
            <section className="two-blocks">
              <div className="col-xl-8 push-xl-2 pull-xl-2">
                <div className="row">
                  {data.service.frontmatter.paragraphs && data.service.frontmatter.paragraphs.map((paragraph, i) => {
                    return (
                      <div className="col-md-6 item" key={i}>
                        <h2>{paragraph.title}</h2>
                        <p className="description-smaller" dangerouslySetInnerHTML={{ __html: snarkdown(paragraph.text) }}/>
                      </div>
                    )
                  })}
                </div>
              </div>
            </section>
          </Reveal>
        {caseStudiePicker.length > 0 &&
            <Reveal>
                <section className={'row ml-0 mr-0 slider servis-slider'}>
                <div className="col-xl-9 push-xl-3">
                    <CaseStudiesOtherProjects
                    responsive = {
                        {
                            0: { items: 2.5 },
                            991: { items: 2 },
                            1555: { items: 3 },
                            1920: { items: 3 },
                            2320: { items: 3.3 },
                        }
                    }
                    category={data.service.frontmatter.category}
                    lang={'sk'} /*TODO*/
                    projects={this.projectsData}
                    title={uppercaseFirstLetter(getCategoryDisplayName(categoryName, 'sk', 'Inflected_2'))}
                    />
                </div>
                </section>
            </Reveal>
        }

          <Reveal>
            <section className="casestudy-block cat-block about-us-projects service">
            <div className="container">
              <h3>{t('ServiceDetail.Process')}</h3>
              <LinkBtn>
                <TranslatedLink to={'/o-nas/ako-pracujeme'}>
                  {t('ServiceDetail.ProcessSubTitle')}
                </TranslatedLink>
              </LinkBtn>
            </div>
            </section>
          </Reveal>
          
          <Reveal>
            <ContaktUs
                LeftSideTitle='category'
                LeftSideSubTitle={data.service.frontmatter.next_page_title}
                LeftSideLink={data.service.frontmatter.next_page_url}
                linkRight="/en/contact"
                rightSecondLink="/en/brief"
            />
            </Reveal>
       
      </Layout>
    )
  }
}

Service.propTypes = { data: PropTypes.any }

export const query = graphql`query ($slug: String!) {
    service: markdownRemark(fields: {slug: {eq: $slug}}) {
        frontmatter {
            language
            category
            title
            description
            projects_url
            next_page_title
            next_page_url
            icon {
                publicURL
            }
            top_paragraphs {
                title
                text
            }
            paragraphs {
                title
                text
            }
            seo {
                title
                description
                keywords
            }
        }
    }
    webpages_corporate_sk: file(relativePath: {eq: "pages/sk/references/web_pages_corporate.md"}) {
        childMarkdownRemark {
            frontmatter {
                other_projects {
                    title
                    url_override
                    show_in_references_slider
                    url
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 700, quality: 75) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    case_study
                }
            }
        }
    }
    webpages_eshop_sk: file(relativePath: {eq: "pages/sk/references/web_pages_eshop.md"}) {
        childMarkdownRemark {
            frontmatter {
                other_projects {
                    title
                    url_override
                    show_in_references_slider
                    url
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 700, quality: 75) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    case_study
                }
            }
        }
    }
    webpages_portal_sk: file(relativePath: {eq: "pages/sk/references/web_pages_portal.md"}) {
        childMarkdownRemark {
            frontmatter {
                other_projects {
                    title
                    url_override
                    show_in_references_slider
                    url
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 700, quality: 75) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    case_study
                }
            }
        }
    }
    webpages_microsite_sk: file(relativePath: {eq: "pages/sk/references/web_pages_microsite.md"}) {
        childMarkdownRemark {
            frontmatter {
                other_projects {
                    title
                    url_override
                    show_in_references_slider
                    url
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 700, quality: 75) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    case_study
                }
            }
        }
    }
    webpages_crm_sk: file(relativePath: {eq: "pages/sk/references/web_pages_crm.md"}) {
        childMarkdownRemark {
            frontmatter {
                other_projects {
                    title
                    url_override
                    show_in_references_slider
                    url
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 700, quality: 75) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    case_study
                }
            }
        }
    }
    applications_sk: file(relativePath: {eq: "pages/sk/references/applications.md"}) {
        childMarkdownRemark {
            frontmatter {
                other_projects {
                    title
                    url_override
                    show_in_references_slider
                    url
                    category
                    thumbnail {
                        childImageSharp {
                            fluid( maxWidth: 550) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    case_study
                }
            }
        }
    }
    logoBranding_sk: file(relativePath: {eq: "pages/sk/references/graphic_logo_branding.md"}) {
        childMarkdownRemark {
            frontmatter {
                other_projects {
                    title
                    url_override
                    show_in_references_slider
                    url
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 700, quality: 75) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    case_study
                }
            }
        }
    }
    digitalPrint_sk: file(relativePath: {eq: "pages/sk/references/graphic_digital_print.md"}) {
        childMarkdownRemark {
            frontmatter {
                other_projects {
                    title
                    url_override
                    show_in_references_slider
                    url
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 700, quality: 75) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    case_study
                }
            }
        }
    }
    caseStudies: file(relativePath: {eq: "pages/sk/homepage/featured_case_studies.md"}) {
      childMarkdownRemark {
          frontmatter {
              case_studies {
                  title
                  background_color
                  case_study
                  description
                  category
                  thumbnail_layers_cs_picker {
                      thumbnail {
                          childImageSharp {
                              fixed(width: 191, height: 124) {
                                  ...GatsbyImageSharpFixed_withWebp
                              }
                          }
                      }
                  }
                  thumbnail_layers {
                      thumbnail {
                          childImageSharp {
                              fluid(maxWidth: 700) {
                                  ...GatsbyImageSharpFluid_withWebp
                              }
                          }
                      }
                  }
              }
          }
      }
  }
}
`

export default Service

