import React, { PureComponent    } from 'react'
import * as PropTypes from 'prop-types'
import { getCategoryDisplayName, getTranslateFunction, getCategorySubCategorySlug } from '../../utilities'
import Reveal from './../reveal'
import MultiLayerTilt from './../MultiLayerTilt'
import { Link } from 'gatsby'
import LinkBtn from './../linkBtn/LinkBtn'
import Img from 'gatsby-image'
import AliceCarousel from '../../lib/react-alice-carousel/react-alice-carousel'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { TranslatedLink } from './../TranslatedLink'

class caseStudiesPicker extends PureComponent    {

    constructor(props) {
        super(props)
        let index = 0
        if (typeof window != 'undefined' && props.loadLast) {
            if (this.props.studies[0]) {
                index = localStorage.getItem("case_studio_" + this.props.studies[0].category) | 0   
            }
        }
        this.state = {
          studie: this.props.studies[index],
          activeItem: 0,
          show: true,
          refreshKey: 1
        }
      this.setIndex = this.setIndex.bind(this)
      
      //BUG - Carosuel not load slide width
      setTimeout(() => {
        this.setState({
          refreshKey: 2
        })
      }, 1000)
    }
  
    setIndex(i) {
        this.setState({
            show: false,
            studie: this.props.studies[i],
            activeItem: i,
        })
        if (typeof window != 'undefined' && this.props.loadLast) {
            localStorage.setItem("case_studio_" + this.state.studie.category, i);
        }
        let that = this
        setTimeout(() => {
            that.setState({
                show: true
            })
        }, 300);
    }
    
    getCaseStudySlugByTitle(caseStudyCategory) {
        const { lang } = this.props
        return `${lang === 'en' ? '/en/our-works/' : '/nase-prace/'}${getCategorySubCategorySlug(caseStudyCategory, lang)}`
    }

    render() {
      
        const setIndex = this.setIndex;
        const { isRight, lang, title, subtitle, loadLast } = this.props
        const t = getTranslateFunction(lang)
        const bgColor = this.state.studie.background_color
        const background = `linear-gradient(105deg, transparent 0%, transparent 60%, ${bgColor} 60%, ${bgColor} 100%)`
        let activeIndex = 0
        if (typeof window != 'undefined' && loadLast) {
            activeIndex = localStorage.getItem("case_studio_" + this.state.studie.category) | this.state.activeItem
        } else {
            activeIndex = this.state.activeItem
        }
        return (
            <Reveal otherClassName={'rotate-animation'}>
                <div className={`item ${isRight ? 'project-right' : 'project-left'}`}>
                    <div className="container">
                        <div className="row">
                            <div className={`col-lg-5 col-xl-4 description mt-0 mt-md-5 text-left ${!isRight ? 'order-2' : ''}`}>
                                <div className='category-desc'>
                                    <h3 className='mb-1 mb-md-2'>{title || getCategoryDisplayName(this.state.studie.category, lang)}</h3>
                                    <p className='type-description'>{subtitle || t('Homepage.FeaturedCaseStudies.' + this.state.studie.category)}</p>
                                </div>
                                    
                                {/*  Projects */}
                                <div className='projects mb-0 mb-md-0'>
                                    <h4 className='mb-3 mb-md-4'>{t('Homepage.FeaturedCaseStudies.selectedProjects')}</h4>
                                    {/* Thumbnails */}
                                    <div className='row mb-3 d-none d-lg-flex'>
                                    
                                        {this.props.studies.map(function (caseStudie, i) {
                                            const thumbnails = caseStudie.thumbnail_layers_cs_picker || caseStudie.thumbnail_layers
                                        return (
                                            <div key={'caseStudie' + i} className={`col-lg-6 thumbnails-cont ${activeIndex === i ? 'active' : ''}`}
                                                onClick={() => {
                                                    setIndex(i)
                                                }}
                                                >
                                                <div>
                                                    {thumbnails.filter(thumb => thumb.thumbnail).map((img, i) => {
                                                        return <Img key={'img' + i} className={'w-100'}  fixed={img.thumbnail.childImageSharp.fixed} alt={caseStudie.title} /> 
                                                    })}
                                                </div>
                                            </div>
                                            );
                                        })
                                        }
                                    </div>
                                    <div className='row mb-2 mb-lg-3 d-block d-lg-none couse-study-picker-slider'>
                                        <AliceCarousel
                                            autoWidth={true}
                                            key={this.state.refreshKey}
                                            mouseTracking={true}
                                            duration={500}
                                     
                                            activeIndex ={activeIndex - 1}
                                            disableDotsControls={true}
                                            disableButtonsControls={true}
                                            autoPlay={false}
                                            autoPlayInterval={10 * 1000}
                                            items={this.props.studies.map((caseStudie, i) => {
                                                const thumbnails = caseStudie.thumbnail_layers_cs_picker || caseStudie.thumbnail_layers
                                                return (
                                                    <div key={'caseStudie' + i} className={`col-lg-6 thumbnails-cont ${activeIndex === i ? 'active' : ''}`} onClick={() => {setIndex(i)}}>
                                                        <div>
                                                            {thumbnails.filter(thumb => thumb.thumbnail).map((img, i) => {
                                                                return <Img key={'img' + i} className={'w-100'}  fixed={img.thumbnail.childImageSharp.fixed} alt={caseStudie.title} /> 
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                            })} />
                                        </div>
                                </div>

                                {/* Project title */}
                                <div className='row m-0 d-flex align-items-center mb-1 mb-md-2 pb-1 project-title mt-lg-2'>
                                    <div className='col-auto mr-auto'>
                                    <Link to={this.state.studie.case_study}>
                                        <h4 className='mb-0'>{this.state.studie.title}</h4>
                                    </Link>
                                    </div>
                                    <div className="col-auto">
                                    
                                    </div>
                                </div>

                                {/* Description */}
                                <div className='project-description'>{this.state.studie.description}</div>

                                <div className="d-flex align-items-center cs-picker-buttons">
                                    <div className="pl-0 mb-4 d-none d-lg-block">
                                        <LinkBtn size="1.2" className={'show-it mr-0'}>
                                            <TranslatedLink to={this.state.studie.case_study}>{t('Homepage.FeaturedCaseStudies.OpenBtn')}</TranslatedLink>
                                        </LinkBtn>
                                    </div>
                                    <div className={'ml-3 mb-4 d-none d-lg-block pr-0'}>
                                        <LinkBtn size="1.2" color="silver" className="mr-0">
                                            <a href={this.getCaseStudySlugByTitle(this.state.studie.category)}>
                                                {t('Homepage.FeaturedCaseStudies.showAll') + ' ' + getCategoryDisplayName(this.state.studie.category, lang, 'Inflected')}
                                            </a>
                                        </LinkBtn>
                                    </div>
                                </div>
                            </div>
                        
                            <div className={`col-10 col-lg-6 col-xl-7 offset-1 offset-lg-0  hp-project-img ${isRight ? 'ml-lg-auto mt-0 mt-lg-5' : 'mt-0 mt-lg-5 mr-lg-auto'}`}>
                                {/*  <TransitionGroup>
                                    
                                        {this.props.studies.map((studie, index) => {
                
                                            return (
                                                <Link className='' to={studie.case_study}>
                                                    <MultiLayerTilt layers={studie.thumbnail_layers.map((img, i) => (
                                                        <Img fluid={img.thumbnail.childImageSharp.fluid} alt={studie.title} className="shadow-img" key={'thumbnail_layers' + i} />
                                                    ))} />
                                                </Link>
                                            )
                                        })}
                                        
                                
                                </TransitionGroup> */}
                                <TransitionGroup>
                                    
                                    
                                <CSSTransition
                                    key={activeIndex}
                                    in={true}
                                    timeout={900}
                                    classNames={'fade'}
                                    >
                                    <Link className="d-block rotated" to={this.state.studie.case_study}>
                                        <div className="slide-item">
                                            <MultiLayerTilt  layers={this.state.studie.thumbnail_layers.filter(thumb => thumb.thumbnail).map((img, i) => {
                                                return <Img
                                                    fluid={img.thumbnail.childImageSharp.fluid}
                                                    alt={this.state.studie.title} className="shadow-img"
                                                    key={'thumbnail_layers' + i}
                                                />
                                            })} />
                                            </div>
                                    </Link>
                                </CSSTransition>
                                    
                                </TransitionGroup>
                                <div className={'d-lg-none'}  id="case-bottom-btn">
                                    <div className={'text-center mb-3'} id="case-show-btn">
                                        <LinkBtn size={1.25}>
                                            <TranslatedLink to={this.state.studie.case_study}>{t('Homepage.FeaturedCaseStudies.OpenBtn')}</TranslatedLink>
                                        </LinkBtn>
                                    </div>
                                    <div>
                                        <LinkBtn  size={1.25} color="silver">
                                            <a href={this.getCaseStudySlugByTitle(this.state.studie.category)}>
                                                {t('Homepage.FeaturedCaseStudies.showAll') + ' ' + getCategoryDisplayName(this.state.studie.category, lang, 'Inflected')}
                                            </a>
                                        </LinkBtn> 
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {this.props.enableBg &&
                        <TransitionGroup>
                                        
                            <CSSTransition
                                key={activeIndex + 'background-element'}
                                in={true}
                                timeout={900}
                                classNames={'fade'}
                                >
                                <div>
                                    <div className={'background-element show ' + (isRight ? '' : 'invert') } style={{ background: background }} />
                                </div>
                            </CSSTransition>
                                
                        </TransitionGroup>
                    }
                </div>
        </Reveal>
        )
  }
}

caseStudiesPicker.propTypes = {
    isRight: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    studies: PropTypes.array.isRequired,
    loadLast: PropTypes.bool
}

caseStudiesPicker.defaultProp = {
    isRight: false,
    enableBg: true,
    title: '',
    subtitle: '',
    loadLast: true
}


export default caseStudiesPicker